import React, { Component } from "react";
import "./IntegrationsMarketplaceDrawer.css";
import { Drawer, Icon } from "antd";
import fireAnalyticsEvent from "../../../helpers/editor/fireAnalyticsEvent";
import { urls } from "../../../data/urls";
import { openNotification } from "../../../helpers/openNotification";
import { getErrorKey } from "../../../helpers/editor/getErrorKey";
import { getErrorValue } from "../../../helpers/editor/getErrorValue";
import { handleBadWebsiteError } from "../../../helpers/handleBadWebsiteError";
import { generatePopupDrawerClass } from "../../../helpers/generatePopupDrawerClass";
import { CrispEvents } from "../../../enums/AnalyticsEventsEnums";
import IntegrationInput from "./integration_inputs/IntegrationInput";
import IntegrationNotAvailable from "./IntegrationNotAvailable";
import IntegrationTitle from "./IntegrationTitle";
import FormIntegrationsMarketplace from "./FormIntegrationsMarketplace";
import { addIntegrationAsync } from "../../../helpers/formIntegrations/addIntegrationAsync";

interface Props {
  formIntegrationsMarketplaceVisible: boolean;
  connectIntegrationVisible: boolean;
  closeFormIntegrationsDrawer: any;
  closeConnectFormIntegrationDrawer: any;
  history: any;
  currentWebsite: any;
  user: any;
  componentItem: any;
  saveWebsiteNewFormIntegrationInState: any;
  toggleNewWebsitePageEditionsDetected: any;
  websites: any;
  formIntegrations: any;
  showConnectFormIntegrationDrawer: any;
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd: any;
  auth: any;
  componentType?: string;
  updateIntegrationId: any;
}
export type MyState = {
  currentFormIntegrationTitle: string;
  currentFormIntegrationHumanTitle: string;
  currentFormIntegrationAvailable: false;
  currentFormIntegrationProOnly: false;
  currentFormIntegrationWebhookMethod: string;
};
class IntegrationsMarketplaceDrawer extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      ...this.state,
      currentFormIntegrationTitle: "",
      currentFormIntegrationHumanTitle: "",
      currentFormIntegrationAvailable: false,
      currentFormIntegrationProOnly: false,
      currentFormIntegrationWebhookMethod: "POST",
    });
  }

  saveConnectedIntegrationsInServer = (newConnectedIntegrationObject: any) => {
    this.props.toggleWaitingForResponseOnWebsiteFormIntegrationAdd(true);

    let currentWebsite = this.props.currentWebsite;

    let accessToken = this.props.auth.accessToken;
    addIntegrationAsync(
      accessToken,
      newConnectedIntegrationObject,
      currentWebsite
    )
      .then(() => {
        // Tell user that the integration is saved and can be reused over the website.

        let message = (
          <div>
            The <b>{this.state.currentFormIntegrationHumanTitle}</b> integration
            has been successfully created and can be reused within the website.
          </div>
        );

        if (this.state.currentFormIntegrationTitle === "zapier") {
          message = (
            <div>
              The <b>{this.state.currentFormIntegrationHumanTitle}</b>{" "}
              integration has been successfully created. Please activate it in
              the{" "}
              <a href={urls.thirdparty.zapierEditor} target="_blank">
                Zapier editor <Icon type="link" />
              </a>
            </div>
          );
        }

        fireAnalyticsEvent.fireCrisp(CrispEvents.addIntegration, {
          subdomain: currentWebsite.subdomain,
          integration_human_title: this.state.currentFormIntegrationHumanTitle,
        });

        openNotification("Done", message, "Close", "success", 10);

        this.props.closeConnectFormIntegrationDrawer();
        this.props.closeFormIntegrationsDrawer();
        this.props.updateIntegrationId(newConnectedIntegrationObject.id)
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.addIntegrationError,
            {
              subdomain: currentWebsite.subdomain,
              integration_human_title: this.state
                .currentFormIntegrationHumanTitle,
              error_type: errorObjectKey,
              error_message: errorObjectValue,
            },
            true
          );

          handleBadWebsiteError(errorData);
          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWaitingForResponseOnWebsiteFormIntegrationAdd(false);
      });
  };

  setParentState = (newState: any) => {
    this.setState(newState);
  };

  displayConnectFormIntegration = () => {
    if (!this.state.currentFormIntegrationAvailable) {
      return (
        <IntegrationNotAvailable
          currentFormIntegrationHumanTitle={
            this.state.currentFormIntegrationHumanTitle
          }
        />
      );
    }

    return (
      <div>
        <IntegrationTitle
          currentFormIntegrationTitle={this.state.currentFormIntegrationTitle}
          currentFormIntegrationHumanTitle={
            this.state.currentFormIntegrationHumanTitle
          }
        />
        <IntegrationInput
          currentFormIntegrationTitle={this.state.currentFormIntegrationTitle}
          currentFormIntegrationHumanTitle={
            this.state.currentFormIntegrationHumanTitle
          }
          currentWebsite={this.props.currentWebsite}
          user={this.props.user}
          websites={this.props.websites}
          componentItem={this.props.componentItem}
          currentFormIntegrationWebhookMethod={
            this.state.currentFormIntegrationWebhookMethod
          }
          saveConnectedIntegrationsInServer={
            this.saveConnectedIntegrationsInServer
          }
          setParentState={this.setParentState}
        />
      </div>
    );
  };

  render() {
    return (
      <Drawer
        title="Form integrations marketplace"
        width={400}
        closable={true}
        onClose={this.props.closeFormIntegrationsDrawer}
        visible={this.props.formIntegrationsMarketplaceVisible}
        className={generatePopupDrawerClass(this.props.componentType)}
      >
        <FormIntegrationsMarketplace
          formIntegrations={this.props.formIntegrations}
          setParentState={this.setParentState}
          showConnectFormIntegrationDrawer={
            this.props.showConnectFormIntegrationDrawer
          }
        />
        <Drawer
          title={
            this.state.currentFormIntegrationHumanTitle !== ""
              ? "Connect " + this.state.currentFormIntegrationHumanTitle
              : "Create an integration"
          }
          width={400}
          closable={true}
          onClose={this.props.closeConnectFormIntegrationDrawer}
          visible={this.props.connectIntegrationVisible}
          className={generatePopupDrawerClass(this.props.componentType)}
          destroyOnClose
        >
          {this.displayConnectFormIntegration()}
        </Drawer>
      </Drawer>
    );
  }
}

export default IntegrationsMarketplaceDrawer;
