import React, { Component, useEffect, useState } from "react";
import "./ContentEditionBox.css";
import {
  Button,
  Icon,
  Switch,
  Tabs,
  Tooltip,
  Collapse,
  Radio,
  Alert,
  Select,
} from "antd";
import { componentsBackgroundOptionsExceptions } from "../../../data/componentsBackgroundOptionsExceptions";
import BackgroundColorPicker from "../background/BackgroundColorPicker";
import generateContentDataObject from "../../../helpers/editor/generateContentDataObject";
import { footerSchemeOptions } from "../../../data/footerSchemeOptions";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  defaultLogoHeight,
  notificationDuration,
} from "../../../data/constants";
import { linkOrButtonOptions } from "../../../data/linkOrButtonOptions";
import BackgroundImagePicker from "../background/BackgroundImagePicker";
import { alwaysWhiteBackgroundComponents } from "../../../data/alwaysWhiteBackgroundComponents";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  addWebsitePageComponent,
  changeComponentGlobalData,
  changeDirectoryContent,
  changeWebsitePageComponentBackgroundActiveOption,
  changeWebsitePageComponentBackgroundVideo,
  changeWebsitePagesStateGlobalData,
  toggleNewWebsitePageEditionsDetected,
} from "../../../store/websitePages/actions";
import {
  changePopupBackgroundActiveOption,
  changePopupBackgroundVideo,
  changePopupData,
  toggleWebsitePopupEditionsDetected,
} from "../../../store/websites/actions";
import BackgroundVideoPicker from "../background/BackgroundVideoPicker";
import { activeOptionType } from "../../../helpers/types/componentBackgroundTypes";
import {
  ChangePopupBackgroundActiveOptionType,
  ChangePopupBackgroundVideoType,
} from "../../../helpers/types/popupTypes";
import AiDescriptionInputComponent from "./components/AiDescriptionInputComponent";
import InputLabel from "../InputLabel";
import AiDescriptionInputWebsite from "./components/AiDescriptionInputWebsite";
import { SetUserStateData, UserState } from "../../../store/user/types";
import DebouncedTextarea, {
  DebouncedTextareaProps,
} from "../../DebouncedTextarea";
import _ from "lodash";
import {
  ChangeComponentGlobalData,
  ChangeDirectoryContent,
  ChangeWebsitePagesStateGlobalData,
  WebsitePagesState,
} from "../../../store/websitePages/types";
import DebouncedInput from "../../DebouncedInput";
import { ChangePopupData } from "../../../store/websites/types";
import { insertIdIntoCode } from "../../../helpers/strings/insertIdIntoCode";
import { setUserStateData } from "../../../store/user/actions";
import { PageComponentsState } from "../../../store/pageComponents/types";
import { PageComponentCategoriesState } from "../../../store/pageComponentCategories/types";
import { getComponentThumbnail } from "../../../helpers/getComponentThumbnail";
import classNames from "classnames";
import { LegacyComponents } from "../../../enums/LegacyComponents";
import { getComponentCategoryItem } from "../../../helpers/getComponentCategoryItem";
import { customGroups } from "../../../data/componentExceptions";
import ImagePicker from "../ImagePicker";
import { formatImageUrl } from "../../../helpers/strings/formatImageUrl";
import { ComponentTypes } from "../../../enums/ComponentTypes";
import { createAdvancedComponentAsync } from "../../../helpers/async/createAdvancedComponentAsync";
import { AuthState } from "../../../store/auth/types";
import { openNotification } from "../../../helpers/openNotification";

const Panel = Collapse.Panel;
const { TabPane } = Tabs;
const { Option } = Select;

interface Props {
  user: UserState;
  pageComponents: PageComponentsState;
  pageComponentCategories: PageComponentCategoriesState;
  websitesPages: WebsitePagesState;
  auth: AuthState;

  displayFields: any;
  hide: any;
  componentType: string;
  componentItem?: any; // we need this to access component's settings. Some may have, e.g., background style settings to choose. In this way the ContentEditionBox will be added a 'tabs' element to choose from content edition and style edition.
  componentTitle?: string;
  currentWebsite?: any;

  // we need to calculate currentPageIndex because some nav/footer settings are not global but specific for a particular page (hide/show, bg color)
  // when invoked from blog currentPageIndex is -1
  currentPageIndex?: any; //needed to change component visibility (not for nav and footer)

  componentKey?: any; //needed to change component visibility (not for nav and footer)
  changeWebsitePageComponentVisibility?: any;

  //bg image and color methods are passed only when a non Nav or non Footer component is edited.
  saveComponentBackgroundColorInStore?: any;
  saveComponentBackgroundImageInStore?: any;
  saveComponentBackgroundImageOverlayOpacityInStore?: any;

  toggleNewEditionsDetected?: any;

  saveWebsiteFooterInState?: any;
  toggleWebsiteFooterEditionsDetected?: any;
  saveWebsiteNavInState?: any;
  toggleWebsiteNavEditionsDetected?: any;

  toggleBlogFooterEditionsDetected?: any;
  toggleBlogNavEditionsDetected?: any;
  saveBlogFooterInState?: any;
  saveBlogNavInState?: any;
  currentBlog?: any;

  isBlogObjectEdited?: any; // isBlogObjectEdited is needed to detect when we call displayFooter in the <BlogEditor>. We need another "save in state" function and we also need to get the nav/footer object not from website but from blog model.

  saveImageInfoInDB: any; //needed for BG image picker
  removeImageFromDB: any; //needed for BG image picker
  toggleIsPageNavHidden: any;
  toggleIsPageFooterHidden: any;
  currentWebsitePage: any;
  toggleNewWebsitePageNavEditionsDetected?: any;
  triggerComponentRemount: () => void;

  changeWebsitePageComponentBackgroundActiveOption?: (payload: {
    currentPageIndex: number;
    componentKey: number;
    activeOption: activeOptionType;
  }) => void;
  changeWebsitePageComponentBackgroundVideo?: (payload: {
    currentPageIndex: number;
    componentKey: number;
    backgroundVideoUrl: string;
  }) => void;

  changePopupBackgroundActiveOption?: (
    payload: ChangePopupBackgroundActiveOptionType
  ) => void;
  changePopupBackgroundVideo?: (
    payload: ChangePopupBackgroundVideoType
  ) => void;
  changeComponentGlobalData?: ChangeComponentGlobalData;
  toggleNewWebsitePageEditionsDetected?: (payload: boolean) => void;
  changePopupData: ChangePopupData;
  toggleWebsitePopupEditionsDetected: (payload: boolean) => void;
  setUserStateData: SetUserStateData;
  addWebsitePageComponent: any;
  changeDirectoryContent: ChangeDirectoryContent;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;
}
interface State {
  backgroundMedia: string;
  backgroundVideoInputValue: string;
}

interface CodeTextAreaProps extends DebouncedTextareaProps {
  type?: "css" | "js";
}
const CodeTextArea = (props: CodeTextAreaProps) => {
  const { type } = props;
  let placeholder = `{{id}} .heading {
  color: red;
}`;
  if (type == "js") {
    placeholder = `const thisComponent = document.getElementById('{{id}}');
thisComponent.classList.add('custom-class');
thisComponent.style.opacity = '0.5';`;
  }
  return (
    <DebouncedTextarea
      autosize={{ minRows: 3, maxRows: 32 }}
      placeholder={placeholder}
      {...props}
    />
  );
};

interface CustomCodeSettingsProps extends Props {}
const CustomCodeSettings = (props: CustomCodeSettingsProps) => {
  const { componentType } = props;

  useEffect(() => {
    props.setUserStateData({
      forceAdvancedEditorTabAsDefault: false,
    });
  }, []);

  let id = _.get(props, "componentItem.id");

  let getTooltip = (type: "CSS" | "JavaScript") => {
    return (
      <>
        Add {type} below this component.
        <br />
        Use {"{{id}}"} to insert ID dynamically.
        {type === "JavaScript" && (
          <>
            <br />
            <br />
            Note that the editor won't execute JavaScript due to security
            reasons.
          </>
        )}
      </>
    );
  };
  let getDefaultValue = (locationKey: string) => {
    return _.get(props.componentItem, locationKey, "");
  };
  let handleOnChange = (value, locationKey: string) => {
    props.changeComponentGlobalData({
      componentId: props.componentItem.id,
      pageId: props.currentWebsitePage.id,
      data: {
        [locationKey]: value,
      },
    });
    props.toggleNewWebsitePageEditionsDetected(true);
  };

  if (componentType === "nav") {
    id = "uni-navigation-bar";
    getTooltip = (type: "CSS" | "JavaScript") => {
      return (
        <>
          Add {type} below the navigation.
          <br />
          Use {"{{id}}"} to insert ID dynamically.
          {type === "JavaScript" && (
            <>
              <br />
              <br />
              Note that the editor won't execute JavaScript due to security
              reasons.
            </>
          )}
        </>
      );
    };
    getDefaultValue = (locationKey: string) => {
      return _.get(
        props.isBlogObjectEdited ? props.currentBlog : props.currentWebsite,
        `nav.${locationKey}`,
        ""
      );
    };
    handleOnChange = (value: string, locationKey: string) => {
      if (props.isBlogObjectEdited) {
        props.saveBlogNavInState({
          currentBlogId: props.currentBlog.id,
          navObject: {
            ...props.currentBlog.nav,
            [locationKey]: value,
          },
        });
        props.toggleBlogNavEditionsDetected(true);
      } else {
        props.saveWebsiteNavInState({
          currentWebsiteSubdomain: props.currentWebsite.subdomain,
          navObject: {
            ...props.currentWebsite.nav,
            [locationKey]: value,
          },
        });
        props.toggleWebsiteNavEditionsDetected(true);
      }
    };
  }

  if (componentType === "footer") {
    id = "footer";
    getTooltip = (type: "CSS" | "JavaScript") => {
      return (
        <>
          Add {type} below the footer.
          <br />
          Use {"{{id}}"} to insert ID dynamically.
          {type === "JavaScript" && (
            <>
              <br />
              <br />
              Note that the editor won't execute JavaScript due to security
              reasons.
            </>
          )}
        </>
      );
    };
    getDefaultValue = (locationKey: string) => {
      return _.get(
        props.isBlogObjectEdited ? props.currentBlog : props.currentWebsite,
        `footer.${locationKey}`,
        ""
      );
    };
    handleOnChange = (value: string, locationKey: string) => {
      if (props.isBlogObjectEdited) {
        props.saveBlogFooterInState({
          currentBlogId: props.currentBlog.id,
          footerObject: {
            ...props.currentBlog.footer,
            [locationKey]: value,
          },
        });
        props.toggleBlogFooterEditionsDetected(true);
      } else {
        props.saveWebsiteFooterInState({
          currentWebsiteSubdomain: props.currentWebsite.subdomain,
          footerObject: {
            ...props.currentWebsite.footer,
            [locationKey]: value,
          },
        });
        props.toggleWebsiteFooterEditionsDetected(true);
      }
    };
  }

  if (componentType === "popups") {
    id = _.get(props, "componentItem.id");
    getTooltip = (type: "CSS" | "JavaScript") => {
      return (
        <>
          Add {type} below this popup.
          <br />
          Use {"{{id}}"} to insert ID dynamically.
          {type === "JavaScript" && (
            <>
              <br />
              <br />
              Note that the editor won't execute JavaScript due to security
              reasons.
            </>
          )}
        </>
      );
    };
    getDefaultValue = (locationKey: string) => {
      return _.get(props.componentItem, locationKey, "");
    };
    handleOnChange = (value: string, locationKey: string) => {
      props.changePopupData({
        currentWebsiteSubdomain: props.currentWebsite.subdomain,
        popupId: props.componentItem.id,
        data: {
          [locationKey]: value,
        },
      });
      props.toggleWebsitePopupEditionsDetected(true);
    };
  }

  return (
    <li className="content-input">
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Panel
          header={<h2 className="content-input__title">Custom code</h2>}
          key="1"
        >
          <InputLabel tooltip={getTooltip("CSS")}>CSS:</InputLabel>
          <div
            className="content-input__input_box"
            style={{ marginBottom: "30px" }}
          >
            <CodeTextArea
              type="css"
              defaultValue={getDefaultValue("customCss")}
              onChange={(value) => {
                handleOnChange(value, "customCss");
              }}
            />
          </div>
          <InputLabel tooltip={getTooltip("JavaScript")} maxWidth="320px">
            JavaScript:
          </InputLabel>
          <div className="content-input__input_box">
            <CodeTextArea
              type="js"
              defaultValue={getDefaultValue("customJs")}
              onChange={(value) => {
                handleOnChange(value, "customJs");
              }}
            />
            {props.user.data.is_meta_data_inputs_visible && (
              <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    const currentJs = insertIdIntoCode(
                      getDefaultValue("customJs"),
                      id
                    );
                    const enclosedJs = `(function() {
${currentJs}
})()`;
                    try {
                      new Function(enclosedJs)();
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  Dangerously execute JS
                </Button>
                <Button
                  onClick={() => {
                    if (componentType !== "nav") {
                      props.setUserStateData({
                        forceAdvancedEditorTabAsDefault: true,
                      });
                    }
                    props.triggerComponentRemount();
                    if (componentType === "popups") {
                      setTimeout(() => {
                        (window.unicornplatform as any).openPopup(
                          props.componentItem.id
                        );
                      }, 0);
                    }
                  }}
                >
                  Refresh
                </Button>
              </div>
            )}
          </div>
          {componentType === ComponentTypes.directory && (
            <div
              className="content-input__input_box"
              style={{ marginTop: "30px" }}
            >
              <InputLabel tooltip="HTML template for directory items. Use {{field_name}} to insert the API data.">
                Directory item HTML:
              </InputLabel>
              <DebouncedTextarea
                defaultValue={_.get(
                  props.componentItem,
                  "componentData.directory.content.directoryItemHtml",
                  ""
                )}
                onChange={(value) => {
                  props.changeDirectoryContent({
                    pageId: props.currentWebsitePage.id,
                    componentId: props.componentItem.id,
                    field: "directoryItemHtml",
                    value,
                  });
                  props.toggleNewWebsitePageEditionsDetected(true);
                }}
                autosize={{ minRows: 3, maxRows: 32 }}
                placeholder="<div>{{title}}</div>"
              />
            </div>
          )}
        </Panel>
      </Collapse>
    </li>
  );
};

interface ClassesAndAttributesSettingsProps extends Props {}
const ClassesAndAttributesSettings = (
  props: ClassesAndAttributesSettingsProps
) => {
  const { componentType } = props;

  let getTooltip = (type: "classes" | "attributes") => {
    return (
      <>
        Add custom HTML {type}
        <br />
        to this component
      </>
    );
  };
  let getDefaultValue = (locationKey: string) => {
    return _.get(props.componentItem, locationKey, "");
  };
  let handleOnChange = (value, locationKey: string) => {
    props.changeComponentGlobalData({
      componentId: props.componentItem.id,
      pageId: props.currentWebsitePage.id,
      data: {
        [locationKey]: value,
      },
    });
    props.toggleNewWebsitePageEditionsDetected(true);
  };

  if (componentType === "nav") {
    getTooltip = (type: "classes" | "attributes") => {
      return (
        <>
          Add custom HTML {type}
          <br />
          to the navigation
        </>
      );
    };
    getDefaultValue = (locationKey: string) => {
      return _.get(
        props.isBlogObjectEdited ? props.currentBlog : props.currentWebsite,
        `nav.${locationKey}`,
        ""
      );
    };
    handleOnChange = (value: string, locationKey: string) => {
      if (props.isBlogObjectEdited) {
        props.saveBlogNavInState({
          currentBlogId: props.currentBlog.id,
          navObject: {
            ...props.currentBlog.nav,
            [locationKey]: value,
          },
        });
        props.toggleBlogNavEditionsDetected(true);
      } else {
        props.saveWebsiteNavInState({
          currentWebsiteSubdomain: props.currentWebsite.subdomain,
          navObject: {
            ...props.currentWebsite.nav,
            [locationKey]: value,
          },
        });
        props.toggleWebsiteNavEditionsDetected(true);
      }
    };
  }

  if (componentType === "footer") {
    getTooltip = (type: "classes" | "attributes") => {
      return (
        <>
          Add custom HTML {type}
          <br />
          to the footer
        </>
      );
    };
    getDefaultValue = (locationKey: string) => {
      return _.get(
        props.isBlogObjectEdited ? props.currentBlog : props.currentWebsite,
        `footer.${locationKey}`,
        ""
      );
    };
    handleOnChange = (value: string, locationKey: string) => {
      if (props.isBlogObjectEdited) {
        props.saveBlogFooterInState({
          currentBlogId: props.currentBlog.id,
          footerObject: {
            ...props.currentBlog.footer,
            [locationKey]: value,
          },
        });
        props.toggleBlogFooterEditionsDetected(true);
      } else {
        props.saveWebsiteFooterInState({
          currentWebsiteSubdomain: props.currentWebsite.subdomain,
          footerObject: {
            ...props.currentWebsite.footer,
            [locationKey]: value,
          },
        });
        props.toggleWebsiteFooterEditionsDetected(true);
      }
    };
  }

  if (componentType === "popups") {
    getTooltip = (type: "classes" | "attributes") => {
      return (
        <>
          Add custom HTML {type}
          <br />
          to this popup
        </>
      );
    };
    getDefaultValue = (locationKey: string) => {
      return _.get(props.componentItem, locationKey, "");
    };
    handleOnChange = (value: string, locationKey: string) => {
      props.changePopupData({
        currentWebsiteSubdomain: props.currentWebsite.subdomain,
        popupId: props.componentItem.id,
        data: {
          [locationKey]: value,
        },
      });
      props.toggleWebsitePopupEditionsDetected(true);
    };
  }

  return (
    <li className="content-input">
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Panel
          header={
            <h2 className="content-input__title">Classes & attributes</h2>
          }
          key="1"
        >
          <div
            className="content-input__inline-input"
            style={{ marginBottom: "10px" }}
          >
            <InputLabel tooltip={getTooltip("classes")}>Classes:</InputLabel>
            <div className="content-input__input_box">
              <DebouncedInput
                placeholder="my-class my-second-class"
                onChange={(value) => {
                  handleOnChange(value, "customClasses");
                }}
                defaultValue={getDefaultValue("customClasses")}
              />
            </div>
          </div>
          <div className="content-input__inline-input">
            <InputLabel tooltip={getTooltip("attributes")}>
              Attributes:
            </InputLabel>
            <div className="content-input__input_box">
              <DebouncedInput
                placeholder='data-type="utility" data-name="utility-block"'
                onChange={(value) => {
                  handleOnChange(value, "customAttributes");
                }}
                defaultValue={getDefaultValue("customAttributes")}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
    </li>
  );
};

interface OtherSettingsProps extends Props {}
const OtherSettings = (props: OtherSettingsProps) => {
  const { componentType } = props;

  if (
    componentType === "nav" ||
    componentType === "footer" ||
    componentType === "popups"
  ) {
    return null;
  }

  const handleOnChange = (value: string) => {
    props.changeComponentGlobalData({
      componentId: props.componentItem.id,
      pageId: props.currentWebsitePage.id,
      data: {
        dynamicVisibility: value,
      },
    });
    props.toggleNewWebsitePageEditionsDetected(true);
  };

  return (
    <li className="content-input">
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Panel header={<h2 className="content-input__title">Other</h2>} key="1">
          <div>
            <InputLabel
              tooltip='Select the visibility of this component based on page access: "Dynamic only" for access via a dynamic slug, "Static only" for access without a slug, or "Default" for always visible.'
              maxWidth="320px"
            >
              Dynamic visibility:
            </InputLabel>
            <div className="content-input__input_box">
              <Select
                defaultValue={_.get(
                  props.componentItem,
                  "dynamicVisibility",
                  "default"
                )}
                style={{ width: "170px" }}
                onChange={handleOnChange}
              >
                <Option value="default">Default</Option>
                <Option value="dynamic">Dynamic only</Option>
                <Option value="static">Static only</Option>
              </Select>
            </div>
          </div>
        </Panel>
      </Collapse>
    </li>
  );
};

interface MetaSettingsProps extends Props {}
const MetaSettings = (props: MetaSettingsProps) => {
  const { componentType, isBlogObjectEdited, componentItem } = props;
  const [isResetLoading, setIsResetLoading] = useState(false);

  const isComponent =
    componentType !== "nav" &&
    componentType !== "footer" &&
    componentType !== "popups";

  if (isBlogObjectEdited) return null;

  if (componentType === "popups") return null;

  if (!_.get(props.user, "data.is_meta_data_inputs_visible")) {
    return null;
  }

  let aiDescriptionToolip =
    "Enter a brief description of this component for AI. Used when generating a website with AI from a template.";
  let aiDescriptionJsx = (
    <AiDescriptionInputComponent
      componentItem={props.componentItem}
      currentWebsitePage={props.currentWebsitePage}
    />
  );

  if (componentType === "nav") {
    aiDescriptionToolip =
      "Enter a brief description of the navigation bar for AI. Used when generating a website with AI from a template.";
    aiDescriptionJsx = (
      <AiDescriptionInputWebsite
        currentWebsite={props.currentWebsite}
        contentType="nav"
      />
    );
  }

  if (componentType === "footer") {
    aiDescriptionToolip =
      "Enter a brief description of the footer for AI. Used when generating a website with AI from a template.";
    aiDescriptionJsx = (
      <AiDescriptionInputWebsite
        currentWebsite={props.currentWebsite}
        contentType="footer"
      />
    );
  }

  const handleOnReset = () => {
    setIsResetLoading(true);
    createAdvancedComponentAsync({
      pageComponentItem: componentItem,
    })
      .then((response) => {
        const data = response.data[0];
        props.changeComponentGlobalData({
          componentId: props.componentItem.id,
          pageId: props.currentWebsitePage.id,
          data: {
            customCss: data.customCss,
            customJs: data.customJs,
            customClasses: data.customClasses,
            customAttributes: data.customAttributes,
            dynamicVisibility: data.dynamicVisibility,
            aiDescription: data.aiDescription,
            thumbnail_url: data.thumbnail_url,
            advanced_description: data.advanced_description,
            native_category_id: data.native_category_id,
            customLayoutGroup: data.customLayoutGroup,
            keywords: data.keywords,
            isAlpineEnabled: data.isAlpineEnabled,
          },
        });
        const directoryItemHtml = _.get(
          data,
          "componentData.directory.content.directoryItemHtml",
          ""
        );
        if (directoryItemHtml) {
          props.changeDirectoryContent({
            pageId: props.currentWebsitePage.id,
            componentId: props.componentItem.id,
            field: "directoryItemHtml",
            value: directoryItemHtml,
          });
        }
        props.toggleNewWebsitePageEditionsDetected(true);
      })
      .catch((e) => {
        console.error(e);
        console.error(e.response)
      })
      .finally(() => {
        setIsResetLoading(false);
      });
  };

  return (
    <li className="content-input">
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Panel
          header={<h2 className="content-input__title">Hidden settings</h2>}
          key="1"
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div>
              <InputLabel tooltip={aiDescriptionToolip}>
                Description for AI:
              </InputLabel>
              <div className="content-input__input_box">{aiDescriptionJsx}</div>
            </div>
            {isComponent && (
              <div>
                <InputLabel tooltip="The thumbnail image that will be displayed as a preview.">
                  Advanced component thumbnail URL:
                </InputLabel>
                <div className="content-input__input_box">
                  <ImagePicker
                    currentImgSrc={_.get(componentItem, "thumbnail_url", "")}
                    saveImageInReduxStore={(uploadedImgSrc) => {
                      props.changeComponentGlobalData({
                        componentId: props.componentItem.id,
                        pageId: props.currentWebsitePage.id,
                        data: {
                          thumbnail_url: uploadedImgSrc,
                        },
                      });
                      props.toggleNewWebsitePageEditionsDetected(true);
                      console.log(formatImageUrl(uploadedImgSrc));
                    }}
                    inputId="thumbnail-url-input"
                    saveImageInfoInDB={() => {}}
                    currentImgUUID={null}
                    removeImageFromDB={() => {}}
                  />
                </div>
              </div>
            )}
            {isComponent && (
              <div>
                <InputLabel tooltip="Enter a brief description of this component. It will be displayed on the component preview.">
                  Advanced component description:
                </InputLabel>
                <div className="content-input__input_box">
                  <DebouncedInput
                    placeholder="Scrollable content cards"
                    defaultValue={_.get(componentItem, "advanced_description")}
                    onChange={(value) => {
                      props.changeComponentGlobalData({
                        componentId: props.componentItem.id,
                        pageId: props.currentWebsitePage.id,
                        data: {
                          advanced_description: value,
                        },
                      });
                      props.toggleNewWebsitePageEditionsDetected(true);
                    }}
                  />
                </div>
              </div>
            )}
            {isComponent && (
              <div>
                <InputLabel tooltip="wip">Native category ID:</InputLabel>
                <div className="content-input__input_box">
                  <DebouncedInput
                    placeholder="123"
                    defaultValue={_.get(componentItem, "native_category_id")}
                    onChange={(value) => {
                      props.changeComponentGlobalData({
                        componentId: props.componentItem.id,
                        pageId: props.currentWebsitePage.id,
                        data: {
                          native_category_id: value,
                        },
                      });
                      props.toggleNewWebsitePageEditionsDetected(true);
                    }}
                  />
                </div>
              </div>
            )}
            {isComponent && (
              <div>
                <InputLabel tooltip="wip">Custom layout group:</InputLabel>
                <div className="content-input__input_box">
                  <DebouncedInput
                    placeholder="banners"
                    defaultValue={_.get(componentItem, "customLayoutGroup")}
                    onChange={(value) => {
                      props.changeComponentGlobalData({
                        componentId: props.componentItem.id,
                        pageId: props.currentWebsitePage.id,
                        data: {
                          customLayoutGroup: value,
                        },
                      });
                      props.toggleNewWebsitePageEditionsDetected(true);
                    }}
                  />
                </div>
              </div>
            )}
            {isComponent && (
              <div>
                <InputLabel tooltip="For search">Keywords:</InputLabel>
                <div className="content-input__input_box">
                  <DebouncedInput
                    placeholder="lorem ipsum dolor sit"
                    defaultValue={_.get(componentItem, "keywords")}
                    onChange={(value) => {
                      props.changeComponentGlobalData({
                        componentId: props.componentItem.id,
                        pageId: props.currentWebsitePage.id,
                        data: {
                          keywords: value,
                        },
                      });
                      props.toggleNewWebsitePageEditionsDetected(true);
                    }}
                  />
                </div>
              </div>
            )}

            <div>
              <InputLabel tooltip="Adds Alpine.js from a cdn if this component is present on the page">
                Enable Alpine.js:
              </InputLabel>
              <div className="content-input__input_box">
                <Switch
                  defaultChecked={_.get(
                    componentItem,
                    "isAlpineEnabled",
                    false
                  )}
                  onChange={(value) => {
                    props.changeComponentGlobalData({
                      componentId: props.componentItem.id,
                      pageId: props.currentWebsitePage.id,
                      data: {
                        isAlpineEnabled: value,
                      },
                    });
                    props.toggleNewWebsitePageEditionsDetected(true);
                  }}
                />
              </div>
            </div>

            {isComponent && !!componentItem.original_component_id && (
              <div>
                <div className="content-input__input_box">
                  <Button
                    type="primary"
                    loading={isResetLoading}
                    onClick={handleOnReset}
                  >
                    Reset to original
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
    </li>
  );
};

interface SwitchComponentSettingsProps extends Props {}
const SwitchComponentSettings = (props: SwitchComponentSettingsProps) => {
  const [loadingComponentKey, setLoadingComponentKey] = useState("");
  const currentCategoryItem = getComponentCategoryItem(
    props.pageComponentCategories,
    props.componentItem
  );
  const currentComponentName = `${currentCategoryItem.title}-${props.componentItem.title}`;
  const legacyComponents = Object.values(LegacyComponents);

  if (legacyComponents.includes(currentComponentName as LegacyComponents)) {
    return null;
  }

  let thisCategoryComponents = props.pageComponents.items.filter(
    (pageComponent) => {
      return pageComponent.category === currentCategoryItem.id;
    }
  );

  let customGroupsArray = [];
  Object.keys(customGroups).forEach((groupKey) => {
    if (customGroups[groupKey].includes(currentComponentName)) {
      customGroupsArray = customGroups[groupKey];
    }
  });

  if (customGroupsArray.includes(currentComponentName)) {
    thisCategoryComponents = props.pageComponents.items.filter(
      (pageComponent) => {
        const categoryTitle = getComponentCategoryItem(
          props.pageComponentCategories,
          pageComponent
        ).title;
        const name = `${categoryTitle}-${pageComponent.title}`;
        return customGroupsArray.includes(name);
      }
    );
  }

  const { advancedComponentsArray } = props.websitesPages;
  let customLayoutGroup = null;
  if (props.componentItem.original_component_id) {
    const originalAdvancedComponentMetaData = advancedComponentsArray.find(
      (advancedComponent) => {
        return (
          advancedComponent.original_component_id ===
          props.componentItem.original_component_id
        );
      }
    );
    if (originalAdvancedComponentMetaData) {
      customLayoutGroup = originalAdvancedComponentMetaData.customLayoutGroup;
    }
  }

  thisCategoryComponents.forEach((pageComponent) => {
    const matchedAdvancedComponents = advancedComponentsArray.filter(
      (advancedComponent) => {
        return (
          advancedComponent.original_component_category_id ===
            pageComponent.category &&
          advancedComponent.original_component_title === pageComponent.title
        );
      }
    );
    thisCategoryComponents = [
      ...thisCategoryComponents,
      ...matchedAdvancedComponents,
    ];
  });

  if (customLayoutGroup) {
    thisCategoryComponents = thisCategoryComponents.filter((pageComponent) => {
      return pageComponent.customLayoutGroup === customLayoutGroup;
    });
  } else {
    thisCategoryComponents = thisCategoryComponents.filter((pageComponent) => {
      return !pageComponent.customLayoutGroup;
    });
  }

  if (thisCategoryComponents.length <= 1) return null;
  if (currentComponentName === "other-01") return null;

  const componentItems = thisCategoryComponents
    .sort((a, b) => {
      const categoryItemA = getComponentCategoryItem(
        props.pageComponentCategories,
        a,
        a.is_advanced
      );
      const categoryItemB = getComponentCategoryItem(
        props.pageComponentCategories,
        b,
        b.is_advanced
      );
      const aName = `${categoryItemA.title}-${a.title}`;
      const bName = `${categoryItemB.title}-${b.title}`;

      if (props.componentItem.initial_original_component_id) {
        if (
          props.componentItem.initial_original_component_id ===
          a.original_component_id
        ) {
          return -1;
        }
        if (
          props.componentItem.initial_original_component_id ===
          b.original_component_id
        ) {
          return 1;
        }
      }

      if (props.componentItem.id.includes(aName)) {
        return -1;
      }
      if (props.componentItem.id.includes(bName)) {
        return 1;
      }

      return 0;
    })
    .map((pageComponent) => {
      if (pageComponent && pageComponent.is_hidden) {
        return null;
      }
      const categoryItem = getComponentCategoryItem(
        props.pageComponentCategories,
        pageComponent,
        pageComponent.is_advanced
      );
      const componentName = `${categoryItem.title}-${
        pageComponent.original_component_title || pageComponent.title
      }`;
      if (legacyComponents.includes(componentName as LegacyComponents)) {
        return null;
      }
      let pageComponentThumbUrl = getComponentThumbnail(
        pageComponent,
        categoryItem
      );

      if (pageComponent.thumbnail_url) {
        pageComponentThumbUrl = pageComponent.thumbnail_url;
      }
      pageComponentThumbUrl = formatImageUrl(pageComponentThumbUrl);

      let buttonKey = componentName;
      if (pageComponent.original_component_id) {
        buttonKey = pageComponent.original_component_id;
      }

      let isSelected = currentComponentName === componentName;

      if (
        pageComponent.original_component_id ||
        props.componentItem.original_component_id
      ) {
        isSelected =
          props.componentItem.original_component_id ===
          pageComponent.original_component_id;
      }

      const isLoading = props.websitesPages.isFullAdvancedComponentBeingFetched;

      return (
        <button
          type="button"
          className={classNames("switch-component__button", {
            "is-selected": isSelected,
            "is-loading": isLoading,
          })}
          key={buttonKey}
          onClick={() => {
            if (isSelected || isLoading) {
              return;
            }
            const componentsArray = _.get(
              props.currentWebsitePage,
              "page_components.data.schema",
              []
            );
            const indexToPlace = componentsArray.length;

            if (pageComponent.is_advanced) {
              props.changeWebsitePagesStateGlobalData({
                data: {
                  isFullAdvancedComponentBeingFetched: true,
                },
              });
              setLoadingComponentKey(buttonKey);
              createAdvancedComponentAsync({
                pageComponentItem: pageComponent,
              })
                .then(({ data }) => {
                  props.addWebsitePageComponent({
                    currentPageIndex: props.currentPageIndex,
                    componentCategoryId:
                      pageComponent.original_component_category_id ||
                      categoryItem.id,
                    componentCategoryTitle:
                      pageComponent.original_component_category_title ||
                      categoryItem.title,
                    componentTitle:
                      pageComponent.original_component_title ||
                      pageComponent.title,
                    pageComponentItem: pageComponent,
                    indexToPlace,
                    additionalProperties: {
                      isForSwitch: true,
                      switchComponentId: props.componentItem.id,
                      isSwitchDataSent: false,
                    },
                    originalAdvancedComponent: data[0],
                  });
                })
                .catch((error) => {
                  console.error(error.response);
                  openNotification(
                    DEFAULT_ERROR_TITLE,
                    DEFAULT_ERROR_MESSAGE,
                    "OK",
                    "error",
                    notificationDuration.medium
                  );
                })
                .finally(() => {
                  props.changeWebsitePagesStateGlobalData({
                    data: {
                      isFullAdvancedComponentBeingFetched: false,
                    },
                  });
                  setLoadingComponentKey("");
                });
              return;
            }
            props.addWebsitePageComponent({
              currentPageIndex: props.currentPageIndex,
              componentCategoryId:
                pageComponent.original_component_category_id || categoryItem.id,
              componentCategoryTitle:
                pageComponent.original_component_category_title ||
                categoryItem.title,
              componentTitle:
                pageComponent.original_component_title || pageComponent.title,
              pageComponentItem: pageComponent,
              indexToPlace,
              additionalProperties: {
                isForSwitch: true,
                switchComponentId: props.componentItem.id,
                isSwitchDataSent: false,
              },
            });
          }}
        >
          <img
            src={pageComponentThumbUrl}
            className="add-page-component__thumb"
          />
          {isSelected && (
            <Icon
              className="switch-component__icon"
              type="check-circle"
              theme="twoTone"
            />
          )}
          {isLoading && loadingComponentKey === buttonKey && (
            <Icon className="switch-component__loading-icon" type="loading" />
          )}
        </button>
      );
    });

  const customCss = _.get(props.componentItem, "customCss", "");
  const customJs = _.get(props.componentItem, "customJs", "");
  let text = "CSS";
  if (customJs) {
    text = "JavaScript";
  }
  if (customCss && customJs) {
    text = "CSS and JavaScript";
  }

  return (
    <li className="content-input">
      <Collapse bordered={false} defaultActiveKey={["1"]}>
        <Panel
          header={<h2 className="content-input__title">Layout</h2>}
          key="1"
        >
          <div className="switch-component">{componentItems}</div>
        </Panel>
      </Collapse>
    </li>
  );
};

class ContentEditionBox extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = this.setInitialState();
  }

  setInitialState() {
    const props = this.props;
    let backgroundMedia = "image";
    let backgroundVideoInputValue = "";

    if (
      props.componentItem &&
      props.componentItem.settings &&
      props.componentItem.settings.background
    ) {
      if (props.componentItem.settings.background.activeOption) {
        backgroundMedia = props.componentItem.settings.background.activeOption;
      }
      if (props.componentItem.settings.background.backgroundVideoUrl) {
        backgroundVideoInputValue =
          props.componentItem.settings.background.backgroundVideoUrl;
      }
    }

    return {
      backgroundMedia,
      backgroundVideoInputValue,
    };
  }

  render() {
    let footerObject: any;
    let navObject: any;
    if (this.props.isBlogObjectEdited) {
      footerObject = this.props.currentBlog.footer;
      navObject = this.props.currentBlog.nav;
    } else {
      footerObject = this.props.currentWebsite.footer;
      navObject = this.props.currentWebsite.nav;
    }

    let displayNavSettingsEditor = () => {
      let generator = new generateContentDataObject();
      // let navObject = this.props.currentWebsite.nav;
      let isFixed =
        navObject.settings.isFixed.desktop &&
        navObject.settings.isFixed.tablets &&
        navObject.settings.isFixed.mobiles; //why 3 isFixeds? read more in frontend/src/helpers/editor/generateContentDataObject.ts: toggleNavFixed

      let changeNavFixedSetting = (e: any) => {
        let newNavObject = generator.toggleNavFixed(navObject, e);

        if (this.props.isBlogObjectEdited) {
          this.props.saveBlogNavInState({
            navObject: newNavObject,
            currentBlogId: this.props.currentBlog.id,
          });
          this.props.toggleBlogNavEditionsDetected(true);
        } else {
          this.props.saveWebsiteNavInState({
            navObject: newNavObject,
            currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
          });
          this.props.toggleWebsiteNavEditionsDetected(true);
        }
      };
      let changeNavIsHiddenSetting = (e: any) => {
        // toggle is_nav_hidden of the page model: website_pages/models.py
        // if true, the nav bar is not displayed on this particular page and vice versa.

        // used only for pages because only pages have currentPageIndex (not for blogs)

        // change store: save the new page object

        // 1) change store: save the new page object
        this.props.toggleIsPageNavHidden({
          currentPageIndex: this.props.currentPageIndex,
          is_nav_hidden: e,
        });

        this.props.toggleNewWebsitePageNavEditionsDetected(true); // only page editor has this function, not blog. nav changes (such as hide/show nav on a particular page or change its background detected)
      };

      return (
        <>
          <li className="content-input">
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              <Panel
                header={
                  <h2 className="content-input__title">
                    Fix navigation bar
                    <span className="content-input__title_info">
                      Stick menu to the top of page.
                    </span>
                  </h2>
                }
                key="1"
              >
                <Switch
                  onChange={(e: any) => changeNavFixedSetting(e)}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                  checked={isFixed}
                />
              </Panel>
            </Collapse>
          </li>

          {this.props.isBlogObjectEdited === false && (
            <li className="content-input">
              <Collapse bordered={false} defaultActiveKey={["1"]}>
                <Panel
                  header={
                    <h2 className="content-input__title">
                      Hide on this page
                      <span className="content-input__title_info">
                        Remove navigation bar.
                      </span>
                    </h2>
                  }
                  key="1"
                >
                  <Switch
                    onChange={(e: any) => changeNavIsHiddenSetting(e)}
                    checkedChildren={
                      <span>
                        <Icon type="eye-invisible" /> hidden
                      </span>
                    }
                    unCheckedChildren={
                      <span>
                        <Icon type="eye" /> visible
                      </span>
                    }
                    checked={this.props.currentWebsitePage.is_nav_hidden}
                  />
                </Panel>
              </Collapse>
            </li>
          )}
        </>
      );
    };
    let displayFooterSchemePicker = () => {
      let generator = new generateContentDataObject();
      // footerObject contains links, buttons, logo and any other footer-related objects. We have different footers for website and blog.
      let footerSettingsActiveSchemeIndex = footerObject.settings.scheme;
      let changeFooterLayout = (footerOptionObject: any) => {
        let newFooterObject = generator.changeFooterScheme(
          footerObject,
          footerOptionObject.schemeIndex
        );

        // We pick a new footer. Each footer layout (scheme) has its own set of content pieces.
        // See footerSchemeOptions.ts to view all schemes' content pieces.

        // Upon change we generate dummy data according to the new layouts' content set.
        if (footerOptionObject.hasCopyrightMarkup) {
          //    already generated (see setUpFooter here: frontend/src/helpers/editor/generateContentDataObject.ts
        }
        if (footerOptionObject.hasSocialLinks) {
          //    already generated (see setUpFooter here: frontend/src/helpers/editor/generateContentDataObject.ts

          newFooterObject = generator.changeFooterContentPieceHasProperty(
            footerObject,
            "socialLinks",
            "hasTitle",
            footerOptionObject.socialLinks.hasTitle
          );
        }
        if (
          footerOptionObject.hasCta &&
          footerObject.componentData.cta === undefined
        ) {
          let navCtaButtons =
            this.props.currentWebsite.nav.componentData.cta.content.buttons;

          let footerCtaButtons = [];

          let navCtaButtonsCount = navCtaButtons.length;
          for (let i = 0; i < navCtaButtonsCount; i++) {
            let currentNavButton = navCtaButtons[i];
            let clonedButton = generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: currentNavButton.title,
              buttonHref: currentNavButton.href,
              isRelayUTM: currentNavButton.isRelayUTM,
              buttonStyleType: currentNavButton.settings.appearance.styleType,
              buttonColor: currentNavButton.settings.appearance.color,
              buttonMobileAppStoreType:
                currentNavButton.settings.appearance.mobileAppStoreType,
              buttonDownloadType:
                currentNavButton.settings.appearance.downloadType,
              ghostColor: currentNavButton.settings.appearance.ghostColor,
              buttonIsTargetBlank: currentNavButton.isTargetBlank,
              pillPrefix: currentNavButton.pillPrefix,
            });
            footerCtaButtons.push(clonedButton);
          }

          let newFooterCtaButtons = generator.setUpCta({
            buttons: footerCtaButtons,
          }).cta;
          newFooterObject = generator.addFooterContent(
            footerObject,
            "cta",
            newFooterCtaButtons
          );
        }
        if (
          footerOptionObject.hasGroupsOfLinks &&
          footerObject.componentData.list === undefined
        ) {
          let newFooterGroups = generator.setUpList({
            label: { default: "Edit the links columns:" },
            hasGroups: footerOptionObject.list.hasGroups,
            maxGroups: footerOptionObject.list.maxGroups,
            groupTitleHasPictogram:
              footerOptionObject.list.groupTitleHasPictogram,
            groupTitleHasTitle: footerOptionObject.list.groupTitleHasTitle,
            groups: [
              generator.createGroupContentObject({
                title: "The Company",
              }),
              generator.createGroupContentObject({
                title: "Resources",
              }),
              generator.createGroupContentObject({
                title: "Features",
              }),
            ],
            items: [
              generator.createListItemContentObject({
                title: "Website Builder",
                href: "#",
                groupIndex: 0,
              }),
              generator.createListItemContentObject({
                title: "Corporate Blog",
                href: "#",
                groupIndex: 0,
              }),
              generator.createListItemContentObject({
                title: "Email Newsletter",
                href: "#",
                groupIndex: 0,
              }),

              // The last ghost item which is used to add new items.
              generator.createListItemContentObject({
                title: "",
                href: "#",
                groupIndex: 0,
              }),

              generator.createListItemContentObject({
                title: "Free SSL",
                href: "#",
                groupIndex: 1,
              }),
              generator.createListItemContentObject({
                title: "MailChimp integration",
                href: "#",
                groupIndex: 1,
              }),
              generator.createListItemContentObject({
                title: "Zapier forms",
                href: "#",
                groupIndex: 1,
              }),

              // The last ghost item which is used to add new items.
              generator.createListItemContentObject({
                title: "",
                href: "#",
                groupIndex: 1,
              }),

              generator.createListItemContentObject({
                title: "Integrations",
                href: "#",
                groupIndex: 2,
              }),
              generator.createListItemContentObject({
                title: "Templates",
                href: "#",
                groupIndex: 2,
              }),
              generator.createListItemContentObject({
                title: "Icons Pack",
                href: "#",
                groupIndex: 2,
              }),

              // The last ghost item which is used to add new items.
              generator.createListItemContentObject({
                title: "",
                href: "#",
                groupIndex: 2,
              }),
            ],
          }).list;
          newFooterObject = generator.addFooterContent(
            footerObject,
            "list",
            newFooterGroups
          );
        }
        if (
          footerOptionObject.hasLogo &&
          footerObject.componentData.logos === undefined
        ) {
          let currentNavLogo =
            this.props.currentWebsite.nav.componentData.navLogo.content;
          let newFooterLogo = generator.setUpLogos({
            fieldTitle: { default: "Logo" },
            label: { default: "Edit the logo:" },
            labelTooltip: { default: "" },
            fieldInfo: { default: "Recommended shape: square" },
            maxItems: 1,
            items: [
              generator.createLogoContentObject({
                uploadedSrc:
                  currentNavLogo && currentNavLogo.src
                    ? currentNavLogo.src
                    : "",
                isTargetBlank: false,
                href: "/",
                height:
                  currentNavLogo && currentNavLogo.height
                    ? currentNavLogo.height
                    : defaultLogoHeight,
              }),
            ],
          }).logos;
          newFooterObject = generator.addFooterContent(
            footerObject,
            "logos",
            newFooterLogo
          );
        }
        // if(footerOptionObject.hasPosts && footerObject.componentData.posts === undefined){
        // }
        // if(footerOptionObject.hasTestimonials && footerObject.componentData.testimonials === undefined){
        // }
        // if(footerOptionObject.hasTwitterFeed && footerObject.componentData.twitterFeed === undefined){
        // }
        if (
          footerOptionObject.hasContacts &&
          footerObject.componentData.contacts === undefined
        ) {
          let newFooterContacts = generator.setUpContacts({
            fieldTitle: { default: "Additional links" },
            label: { default: "Add some more links:" },
            labelTooltip: {
              default: "Perfect for legal links that matter not so much.",
            },
            fieldInfo: { default: "" },

            items: [
              {
                title: "hi@unicornplatform.com",
                body: "mailto:hi@unicornplatform.com",
              },
              {
                title: "Privacy Policy",
                body: "/privacy-policy",
              },
              {
                title: "Terms of Service",
                body: "/terms-of-service",
              },
              {
                title: "",
                body: "",
              },
            ],

            hasMap: footerOptionObject.contacts.hasMap,
            mapHasTitle: footerOptionObject.contacts.mapHasTitle,
            isMapEmbedded: footerOptionObject.contacts.isMapEmbedded,
            hasContactsItems: footerOptionObject.contacts.hasContactsItems,
            contactsItemHasTitle:
              footerOptionObject.contacts.contactsItemHasTitle,
          }).contacts;
          newFooterObject = generator.addFooterContent(
            footerObject,
            "contacts",
            newFooterContacts
          );
        }

        if (this.props.isBlogObjectEdited) {
          this.props.saveBlogFooterInState({
            footerObject: newFooterObject,
            currentBlogId: this.props.currentBlog.id,
          });
          this.props.toggleBlogFooterEditionsDetected(true);
        } else {
          this.props.saveWebsiteFooterInState({
            footerObject: newFooterObject,
            currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
          });
          this.props.toggleWebsiteFooterEditionsDetected(true);
        }

        {
          /*on change: if new ContentInputs appeared, generate new dummy data by adding new componentData items-.*/
        }
      };

      let displayButtons = () => {
        const buttons = footerSchemeOptions.map((item: any, key: number) => {
          let isCurrentButtonActive =
            item.schemeIndex === footerSettingsActiveSchemeIndex;

          return (
            <button
              key={"footer-scheme-" + key}
              className={classNames("switch-component__button is-full-width", {
                "is-selected": isCurrentButtonActive,
              })}
              onClick={() => {
                changeFooterLayout(item);
              }}
            >
              <img
                src={item.previewUrl}
                className="add-page-component__thumb"
              />
              {isCurrentButtonActive && (
                <Icon
                  className="switch-component__icon"
                  type="check-circle"
                  theme="twoTone"
                />
              )}
            </button>
          );
        });

        return buttons;
      };

      return (
        <>
          <li className="content-input">
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              <Panel
                header={
                  <h2 className="content-input__title">
                    Change footer layout
                    <span className="content-input__title_info">
                      Affects each page.
                    </span>
                  </h2>
                }
                key="1"
              >
                <div className="switch-component ">{displayButtons()}</div>
              </Panel>
            </Collapse>
          </li>
        </>
      );
    };

    let displayBackgroundEditor = (
      currentComponentBackgroundOptions: any,
      isAlwaysWhiteBg: any,
      isFooter: boolean
    ) => {
      const backgroundInfo = {
        style: {
          title: "Background style",
          label: "Choose a background color",
          tooltip: (
            <span>
              Go to the settings menu to set
              <br />
              another color palette.
            </span>
          ),
        },
        media: {
          image: {
            title: "Background image",
            label: "Upload a background image",
            tooltip:
              "Upload an image from your computer, your cloud, or a direct URL.",
          },
          video: {
            title: "Background video",
            label: "Enter a background video URL",
            tooltip: "Enter a direct URL to a video file (must end on '.mp4').",
          },
        },
      };

      let toggleNewEditionsDetected: any;

      if (isFooter) {
        if (this.props.isBlogObjectEdited) {
          toggleNewEditionsDetected =
            this.props.toggleBlogFooterEditionsDetected;
        } else {
          toggleNewEditionsDetected =
            this.props.toggleWebsiteFooterEditionsDetected;
        }
      } else {
        toggleNewEditionsDetected = this.props.toggleNewEditionsDetected;
      }

      const displayTitle = (titleText: string) => {
        return <h2 className="content-input__title">{titleText}</h2>;
      };

      const displaylabel = (
        tooltipContent: String | JSX.Element,
        labelContent: String
      ) => {
        return (
          <div className="content-input__label_box">
            <div className="content-input__tooltip_box">
              <Tooltip placement="top" title={tooltipContent}>
                <Icon
                  className="content-input__tooltip_icon"
                  type="info-circle"
                />
              </Tooltip>
            </div>
            <label className="content-input__label">{labelContent}</label>
          </div>
        );
      };

      const displayBackgroundStyleEditor = () => {
        return (
          <Collapse bordered={false} defaultActiveKey={["1"]}>
            <Panel header={displayTitle(backgroundInfo.style.title)} key="1">
              {displaylabel(
                backgroundInfo.style.tooltip,
                backgroundInfo.style.label
              )}
              <BackgroundColorPicker
                currentWebsite={this.props.currentWebsite}
                componentItem={this.props.componentItem}
                saveComponentBackgroundColorInStore={
                  this.props.saveComponentBackgroundColorInStore
                }
                toggleNewEditionsDetected={toggleNewEditionsDetected}
                currentComponentBackgroundOptions={
                  currentComponentBackgroundOptions
                }
                isFooter={isFooter}
                footerObject={footerObject}
              />
            </Panel>
          </Collapse>
        );
      };

      const displayBackgroundImageOrVideoEditor = () => {
        const handleOnChangeActiveOption = (event) => {
          this.setState({
            backgroundMedia: event.target.value,
          });

          this.props.toggleNewEditionsDetected(true);

          if (this.props.componentType === "popups") {
            this.props.changePopupBackgroundActiveOption({
              currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
              popupId: this.props.componentItem.id,
              activeOption: event.target.value,
            });
            return;
          }

          this.props.changeWebsitePageComponentBackgroundActiveOption({
            currentPageIndex: this.props.currentPageIndex,
            componentKey: this.props.componentKey,
            activeOption: event.target.value,
          });
        };

        const handleOnChangeBackgroundVideo = (value) => {
          this.setState({
            backgroundVideoInputValue: value,
          });

          this.props.toggleNewEditionsDetected(true);

          if (this.props.componentType === "popups") {
            this.props.changePopupBackgroundVideo({
              currentWebsiteSubdomain: this.props.currentWebsite.subdomain,
              popupId: this.props.componentItem.id,
              backgroundVideoUrl: value,
            });
            return;
          }

          this.props.changeWebsitePageComponentBackgroundVideo({
            currentPageIndex: this.props.currentPageIndex,
            componentKey: this.props.componentKey,
            backgroundVideoUrl: value,
          });
        };

        const displayPicker = () => {
          if (this.state.backgroundMedia === "image") {
            return (
              <BackgroundImagePicker
                currentWebsite={this.props.currentWebsite}
                componentItem={this.props.componentItem}
                toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
                currentComponentBackgroundOptions={
                  currentComponentBackgroundOptions
                }
                saveImageInfoInDB={this.props.saveImageInfoInDB}
                removeImageFromDB={this.props.removeImageFromDB}
                saveComponentBackgroundImageInStore={
                  this.props.saveComponentBackgroundImageInStore
                }
                saveComponentBackgroundImageOverlayOpacityInStore={
                  this.props.saveComponentBackgroundImageOverlayOpacityInStore
                }
              />
            );
          }
          return (
            <BackgroundVideoPicker
              currentWebsite={this.props.currentWebsite}
              componentItem={this.props.componentItem}
              toggleNewEditionsDetected={this.props.toggleNewEditionsDetected}
              saveComponentBackgroundImageOverlayOpacityInStore={
                this.props.saveComponentBackgroundImageOverlayOpacityInStore
              }
              placeholder="https://example.com/video.mp4"
              debounceDelay={1000}
              onChange={handleOnChangeBackgroundVideo}
              currentBackgroundVideoSrc={this.state.backgroundVideoInputValue}
            />
          );
        };

        {
          /*footer does not have BG image*/
        }
        if (!isFooter && !isAlwaysWhiteBg) {
          return (
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              <Panel
                header={displayTitle(
                  backgroundInfo.media[this.state.backgroundMedia].title
                )}
                key="1"
              >
                {displaylabel(
                  backgroundInfo.media[this.state.backgroundMedia].tooltip,
                  backgroundInfo.media[this.state.backgroundMedia].label
                )}
                {displayPicker()}
                <Radio.Group
                  className="content-input__active_type"
                  defaultValue={this.state.backgroundMedia}
                  size="small"
                  onChange={handleOnChangeActiveOption}
                >
                  <Radio.Button value="image" key={1}>
                    <span className="content-input__button-text">Image</span>
                  </Radio.Button>
                  <Radio.Button value="video" key={2}>
                    <span className="content-input__button-text">Video</span>
                  </Radio.Button>
                </Radio.Group>
              </Panel>
            </Collapse>
          );
        }
        return null;
      };

      return (
        <>
          <li className="content-input">{displayBackgroundStyleEditor()}</li>
          <li className="content-input">
            {displayBackgroundImageOrVideoEditor()}
          </li>
          {this.props.componentType === "footer" && displayFooterSchemePicker()}
        </>
      );
    };

    let displayVisibilitySettingsEditor = (
      isComponentHiddenOnDesktops,
      isComponentHiddenOnTablets,
      isComponentHiddenOnMobiles
    ) => {
      let toggleComponentVisibility = (e: any, resolution) => {
        this.props.toggleNewEditionsDetected(true);
        this.props.changeWebsitePageComponentVisibility({
          currentPageIndex: this.props.currentPageIndex,
          componentKey: this.props.componentKey, //componentItem
          resolution: resolution,
          isHidden: !e,
        });
      };

      return (
        <>
          <li className="content-input">
            <Collapse bordered={false} defaultActiveKey={["1"]}>
              <Panel
                header={
                  <h2 className="content-input__title">
                    Display or hide the component
                  </h2>
                }
                key="1"
              >
                <div className="content-input__switch_box">
                  <Switch
                    onChange={(e: any) =>
                      toggleComponentVisibility(e, "desktop")
                    }
                    checkedChildren={<Icon type="eye" />}
                    unCheckedChildren={<Icon type="eye-invisible" />}
                    checked={!isComponentHiddenOnDesktops}
                  />
                  <label className="content-input__switch_box_label">
                    Display on desktops
                  </label>
                </div>
                <div className="content-input__switch_box">
                  <Switch
                    onChange={(e: any) =>
                      toggleComponentVisibility(e, "tablet")
                    }
                    checkedChildren={<Icon type="eye" />}
                    unCheckedChildren={<Icon type="eye-invisible" />}
                    checked={!isComponentHiddenOnTablets}
                  />
                  <label className="content-input__switch_box_label">
                    Display on tablets
                  </label>
                </div>
                <div className="content-input__switch_box">
                  <Switch
                    onChange={(e: any) =>
                      toggleComponentVisibility(e, "mobile")
                    }
                    checkedChildren={<Icon type="eye" />}
                    unCheckedChildren={<Icon type="eye-invisible" />}
                    checked={!isComponentHiddenOnMobiles}
                  />
                  <label className="content-input__switch_box_label">
                    Display on phones
                  </label>
                </div>
              </Panel>
            </Collapse>
          </li>
        </>
      );
    };

    let displayContentEditionFields = () => {
      let currentComponentBackgroundOptions = ["color"]; //default for all
      if (
        componentsBackgroundOptionsExceptions[this.props.componentType] &&
        componentsBackgroundOptionsExceptions[this.props.componentType][
          this.props.componentTitle
        ]
      ) {
        currentComponentBackgroundOptions =
          componentsBackgroundOptionsExceptions[this.props.componentType][
            this.props.componentTitle
          ]; //some components may have [] as the BG options array, means they do not have BG options.
      }

      let isComponentHasStyleSettings =
        currentComponentBackgroundOptions.length > 0 &&
        this.props.componentType !== "nav"; // this condition may be improved in future if we add more style settings besides BG.

      let isFooter = this.props.componentType === "footer";
      let isComponentHasFooterSchemeSettings = isFooter;

      let isComponentHasNavFixedSettings = this.props.componentType === "nav";

      // start of visibility settings
      // this code is also used in frontend/src/pageComponents/PageComponent.tsx
      let isComponentHasVisibilitySettings =
        this.props.componentType !== "nav" &&
        this.props.componentType !== "footer" &&
        this.props.componentType !== "popups"; // we can hide component but not nav or footer
      let isComponentHiddenOnDesktops = false;
      let isComponentHiddenOnTablets = false;
      let isComponentHiddenOnMobiles = false;
      if (
        this.props.componentItem === undefined ||
        this.props.componentItem.settings === undefined ||
        this.props.componentItem.settings.visibility === undefined
      ) {
        // if the settings.visibility object is not defined, the component is visible on all devices by default
      } else {
        isComponentHiddenOnDesktops =
          this.props.componentItem.settings.visibility.isHiddenOnDesktops;
        isComponentHiddenOnTablets =
          this.props.componentItem.settings.visibility.isHiddenOnTablets;
        isComponentHiddenOnMobiles =
          this.props.componentItem.settings.visibility.isHiddenOnMobiles;
      }
      // end of visibility settings

      let isAlwaysWhiteBg = false;
      if (
        this.props.componentTitle &&
        alwaysWhiteBackgroundComponents[this.props.componentType] &&
        alwaysWhiteBackgroundComponents[this.props.componentType].indexOf(
          this.props.componentTitle
        ) !== -1
      ) {
        isAlwaysWhiteBg = true;
      }
      const isSwitchComponentSettingsVisible =
        this.props.componentType !== "nav" &&
        this.props.componentType !== "footer" &&
        this.props.componentType !== "popups";

      return (
        <Tabs
          defaultActiveKey={
            this.props.user.forceAdvancedEditorTabAsDefault
              ? "advanced"
              : "content"
          }
        >
          <TabPane
            tab={
              <span>
                <Icon type="edit" />
                Content
              </span>
            }
            key="content"
          >
            {this.props.displayFields()}
          </TabPane>

          <TabPane
            tab={
              <span>
                <Icon type="bg-colors" />
                Style
              </span>
            }
            key="style"
          >
            <div className="page-component__box">
              <ul className="page-component__editor_fields_list">
                {isComponentHasStyleSettings &&
                  displayBackgroundEditor(
                    currentComponentBackgroundOptions,
                    isAlwaysWhiteBg,
                    isFooter
                  )}
                {isComponentHasNavFixedSettings && displayNavSettingsEditor()}
                {isSwitchComponentSettingsVisible && (
                  <SwitchComponentSettings {...this.props} />
                )}
                {isComponentHasVisibilitySettings &&
                  displayVisibilitySettingsEditor(
                    isComponentHiddenOnDesktops,
                    isComponentHiddenOnTablets,
                    isComponentHiddenOnMobiles
                  )}
              </ul>
            </div>
          </TabPane>

          <TabPane
            tab={
              <span>
                <Icon type="setting" />
                Advanced
              </span>
            }
            key="advanced"
          >
            <div className="page-component__box">
              <ul className="page-component__editor_fields_list">
                <CustomCodeSettings {...this.props} />
                <ClassesAndAttributesSettings {...this.props} />
                <OtherSettings {...this.props} />
                <MetaSettings {...this.props} />
              </ul>
            </div>
          </TabPane>
        </Tabs>
      );
    };

    return (
      <div>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            textAlign: "right",
            zIndex: 1,
          }}
          className="js-tour__back_to_editor"
        >
          <Tooltip
            title="Collapse this window. You still need to save the changes."
            placement="bottomRight"
          >
            <Button
              icon="arrow-right"
              onClick={this.props.hide}
              htmlType="button"
            />
          </Tooltip>
        </div>

        {displayContentEditionFields()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    pageComponents: state.pageComponents,
    pageComponentCategories: state.pageComponentCategories,
    websitesPages: state.websitesPages,
    auth: state.auth,
  };
};

const actionCreators = {
  changeWebsitePageComponentBackgroundActiveOption,
  changeWebsitePageComponentBackgroundVideo,

  changePopupBackgroundActiveOption,
  changePopupBackgroundVideo,
  changeComponentGlobalData,
  toggleNewWebsitePageEditionsDetected,
  changePopupData,
  toggleWebsitePopupEditionsDetected,
  setUserStateData,
  addWebsitePageComponent,
  changeDirectoryContent,
  changeWebsitePagesStateGlobalData,
};
export default connect(mapStateToProps, actionCreators)(ContentEditionBox);
